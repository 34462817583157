<template>
  <div id="mian">
    <div class="header">
      <span
        @click="navClick('批量订单查询')"
        :class="crumbs == '批量订单查询' ? 'active' : 'left'"
        >批量查询</span
      >
      <span
        @click="navClick('订单查询')"
        :class="crumbs == '订单查询' ? 'active' : 'left'"
        >订单查询</span
      >
    </div>
    <!-- 批量订单查询 -->
    <div v-if="crumbs == '批量订单查询'">
      <!-- 上面表单框 -->
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>商户名称</i>
            <el-select
              v-model="query.keyword"
              filterable
              clearable
              :filter-method="searchMchList"
              @visible-change="unfoldMchList"
              @clear="clearMchList"
              @change="changeMch"
              placeholder="商户名称/店铺名称"
              :no-data-text="MerchantShopData=='' ? '无数据':'可搜索'"
            >
              <el-option
                v-for="item in MerchantShopData"
                :key="item.mchId"
                :label="item.mchName + ' | ' + item.mchId"
                :value="item.mchId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <i>报备商户号</i>
            <el-input
              v-model="query.tradeMchNo"
              maxlength="128"
              suffix-icon="el-icon-search"
              placeholder="报备商户号"
            ></el-input>
          </div>
          <div class="item">
            <i>产品类型</i> 
            <el-select
              v-model="query.productId"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="(v, i) in productDrop"
                :key="i"
                :label="v.productName"
                :value="v.productId"
              ></el-option>
            </el-select>
          </div>
          <!-- 带有全部选项的下拉组件 -->
          <!-- <drop-select :list="productDrop" @selectId="selProduct"></drop-select> -->
        </div>
        <div class="lineP">
          <div class="item">
            <i>订单状态</i>
            <el-select
              v-model="query.orderStatus"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="未支付" value="NOTPAY"></el-option>
              <el-option label="订单关闭" value="CLOSED"></el-option>
              <el-option label="支付成功" value="SUCCESS"></el-option>
              <el-option label="转入退款" value="REFUND"></el-option>
              <el-option label="支付失败" value="PAYERROR"></el-option>
            </el-select>
          </div>
          <div class="item">
            <i>产品通道</i>
            <el-select
              v-model="query.payChannelId"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="(v, i) in channelsDrop"
                :key="i"
                :label="v.payChannelName"
                :value="v.payChannelId"
              ></el-option>
            </el-select>
          </div>
          <div class="item">
            <i>交易金额</i>
            <!-- oninput="value=value.replace(/[^\d.]/g,'')" -->
            <el-input
              type="number"
              min="0"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              @input="inputnumStart"
              v-model="query.floorAmount"
              class="inputW"
            ></el-input>
            <span style="padding-left: 10px; padding-right: 10px">-</span>
            <el-input
              type="number"
              min="0"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              @input="inputnumEnd"
              v-model="query.topAmount"
              class="inputW"
            ></el-input>
          </div>
        </div>
        <div class="lineP">
          <div class="item">
            <i>是否分账</i>
            <el-select
              v-model="query.allocFund"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </div>
          <div class="item itemTime">
            <i>时间类型</i>
            <el-select
              v-model="query.timeType"
              style="width: 130px;"
            >
              <el-option label="创建时间" value="CREATE"></el-option>
              <el-option label="交易时间" value="TRADE"></el-option>
            </el-select>
            <span style="padding: 0 10px"></span>
            <el-date-picker
              v-model="query.startTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">-</span>
            <el-date-picker
              v-model="query.endTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="lineP">
          <div class="itembtn">
            <el-button @click="getLists" class="searchBt">查询</el-button>
            <el-button @click="outputShow" class="outputBt" v-auth="'TRADE:RECEIPT:ORDERS/EXPORT'">导出</el-button>
          </div>
        </div>
      </div>
      <!-- 中间交易账目 -->
      <!-- <div class="jiaoyi">
        <div class="jiaoyi_box" style="margin-right: 16px">
          <p><i>交易总数（笔）</i></p>
          <p>
            <b>{{ tradeNum }}</b>
          </p>
        </div>
        <div class="jiaoyi_box">
          <p><i>交易金额（元）</i></p>
          <p>
            <b>{{ (tradeAmount / 100) | formatMoney }}</b>
          </p>
        </div>
      </div> -->
      <data-panel :dataPanelList="tradeList"></data-panel>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>创建时间</p>
              <p>交易时间</p>
            </td>
            <td>
              <p>商户名称</p>
              <p>店铺名称</p>
            </td>
            <td>
              <p>报备商户</p>
              <p>报备商户号</p>
            </td>
            <td>
              <p>商户订单号</p>
              <p>交易流水号</p>
            </td>
            <td>
              <p>产品类型</p>
            </td>
            <td>
              <p>订单状态</p>
            </td>
            <td>
              <p>交易金额 (元)</p>
            </td>
            <td>
              <p>退款次数</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="`${i}s`">
            <td>
              <p>{{ v.createTime }}</p>
              <p>{{ v.tradeTime }}</p>
            </td>
            <td class="break">
              <p>{{ v.mchName }}</p>
              <p>{{ v.storeName }}</p>
            </td>
            <td class="break">
              <p>{{ v.tradeMchName ? v.tradeMchName : "" }}</p>
              <p>{{ v.tradeMchNo ? v.tradeMchNo : "" }}</p>
            </td>
            <td>
              <p>{{ v.mchOrderNo }}</p>
              <p>{{ v.plfOrderNo }}</p>
            </td>
            <td>
              <p>{{ v.productName }}</p>
            </td>
            <td>
              <p>{{ orderStatus[v.orderStatus] }}</p>
            </td>
            <td>
              <p>{{ (v.orderAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ v.refundNum }}</p>
            </td>
            <td>
              <p>
                <i
                  v-auth="'TRADE:RECEIPT:ORDERS/REFUND'"
                  v-if="v.orderStatus == 'SUCCESS' || v.orderStatus == 'REFUND'"
                  class="lianjie"
                  @click="setRefundShow(v)"
                  >退款</i
                >
                <i
                  v-auth="'TRADE:RECEIPT:ORDERS/DETAIL'"
                  class="lianjie"
                  @click="showTab(v)"
                  >详情</i
                >
              </p>
              <p>
                <i
                  v-auth="'TRADE:RECEIPT:ORDERS/SYNC'"
                  class="lianjie"
                  @click="getSync(v.orderId, i)"
                  >同步</i
                >
                <i
                  v-auth="'TRADE:RECEIPT:ORDERS/NOTIFY'"
                  v-if="v.orderStatus == 'SUCCESS' || v.orderStatus == 'REFUND'"
                  class="lianjie"
                  @click="setNotify(v.orderId)"
                  >通知</i
                >
              </p>
            </td>
          </tr>
        </table>
      </div>

      <el-pagination
        ref="pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNum"
      >
      </el-pagination>
    </div>
    <!-- 订单查询 -->
    <div v-if="crumbs == '订单查询'">
      <div class="con_from">
        <div class="lineP">
          <!-- <i>商户订单号</i> -->
          <div class="item itemTwo">
            <el-select
              v-model="formData.orderNoType"
              style="width: 170px; margin-right: 20px"
            >
              <el-option value="mchOrderNo" label="商户订单号"></el-option>
              <el-option value="plfOrderNo" label="交易流水号"></el-option>
              <el-option value="upsOrderNo" label="三方订单号"></el-option>
              <el-option value="thdOrderNo" label="三方流水号"></el-option>
            </el-select>
            <el-input
              v-model="formData.orderNo"
              style="width: calc(100% - 170px - 20px);"
              maxlength="128"
              suffix-icon="el-icon-search"
              :placeholder="'搜索' + orderNoType[formData.orderNoType]"
            ></el-input>
          </div>
          <div class="itembtn itemTwobtn">
            <el-button class="searchBt" @click="getDetail">查询</el-button>
          </div>
        </div>
      </div>
      <div class="tab1" style="min-height: 700px">
        <table class="tab">
          <tr class="tab_title">
            <td style="padding-left: 15px">
              <p>创建时间</p>
              <p>交易时间</p>
            </td>
            <td style="width: 80px">
              <p>用户名称</p>
              <p>店铺名称</p>
            </td>
            <td style="width: 80px">
              <p>报备商户</p>
              <p>报备商户号</p>
            </td>
            <td>
              <p>商户订单号</p>
              <p>交易流水号</p>
            </td>
            <td>
              <p>产品类型</p>
            </td>
            <td>
              <p>订单状态</p>
            </td>
            <td>
              <p>交易金额 (元)</p>
            </td>
            <td>
              <p>退款次数</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td>
              <p>{{ v.createTime }}</p>
              <p>{{ v.tradeTime }}</p>
            </td>
            <td style="width: 80px">
              <p>{{ v.mchName }}</p>
              <p>{{ v.storeName }}</p>
            </td>
            <td style="width: 80px">
              <p>{{ v.tradeMchName ? v.tradeMchName : "" }}</p>
              <p>{{ v.tradeMchNo ? v.tradeMchNo : "" }}</p>
            </td>
            <td>
              <p>{{ v.mchOrderNo }}</p>
              <p>{{ v.plfOrderNo }}</p>
            </td>
            <td>
              <p>{{ v.productName }}</p>
            </td>
            <td>
              <p>{{ orderStatus[v.orderStatus] }}</p>
            </td>
            <td>
              <p>{{ (v.orderAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ v.refundNum }}</p>
            </td>
            <td>
              <p>
                <i
                  v-auth="'TRADE:RECEIPT:ORDERS/REFUND'"
                  v-if="v.orderStatus == 'SUCCESS' || v.orderStatus == 'REFUND'"
                  class="lianjie"
                  @click="setRefundShow(v)"
                  >退款</i
                >
                <i
                  v-auth="'TRADE:RECEIPT:ORDERS/DETAIL'"
                  class="lianjie"
                  @click="showTab(v)"
                  >详情</i
                >
              </p>
              <p>
                <i
                  v-auth="'TRADE:RECEIPT:ORDERS/SYNC'"
                  class="lianjie"
                  @click="getSync(v.orderId, i)"
                  >同步</i
                >
                <i
                  v-auth="'TRADE:RECEIPT:ORDERS/NOTIFY'"
                  v-if="v.orderStatus == 'SUCCESS' || v.orderStatus == 'REFUND'"
                  class="lianjie"
                  @click="setNotify(v.orderId)"
                  >通知</i
                >
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <el-dialog title="导出交易记录" :visible.sync="outPayShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title">商户名称</div>
          <el-select v-model="outputForm.mchId" placeholder="选择商户">
            <el-option
              v-for="v in mchDrop"
              :key="v.mchId"
              :label="v.mchName"
              :value="v.mchId"
            ></el-option>
          </el-select>
          <div class="overAccount_ul_title" v-show="selectTimeType == -1">
            <i>*</i>开始日期
          </div>
          <el-date-picker
            style="width: 200px; height: 36px"
            v-model="outputForm.startDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择时间"
            v-show="selectTimeType == -1"
            :disabled="selectTimeType != -1"
          >
          </el-date-picker>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>交易日期</div>
          <el-select
            v-model="selectTimeType"
            @change="timeChange"
            placeholder="选择交易日期"
          >
            <el-option label="今天" value="1"></el-option>
            <el-option label="昨天" value="2"></el-option>
            <el-option label="近7天" value="7"></el-option>
            <el-option label="其它" value="-1"></el-option>
          </el-select>
          <div class="overAccount_ul_title" v-show="selectTimeType == -1">
            <i>*</i>结束日期
          </div>
          <el-date-picker
            style="width: 200px; height: 36px"
            :picker-options="endOption"
            v-model="outputForm.endDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择时间"
            v-show="selectTimeType == -1"
            :disabled="selectTimeType != -1"
          >
          </el-date-picker>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="outPayShow = false">取 消</el-button>
        <el-button class="addBt" @click="output" :disabled="buttonFlag">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="申请退款" :visible.sync="outMoneyShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title">可退金额(元)</div>
          <div style="line-height: 36px">
            {{ (refundForm.refundFee / 100) | formatMoney }}
          </div>
          <div style="line-height: 36px">
            <el-checkbox v-model="allRefund" @change="setRefundMoney"
              >全额退款</el-checkbox
            >
          </div>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>退款金额(元)</div>
          <el-input
            type="number"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="refundMoney"
            placeholder="输入退款金额"
            step="0.01"
            min="0.01"
          ></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="outMoneyShow = false"
          >取 消</el-button
        >
        <el-button class="addBt" @click="setRefund" :disabled="buttonFlag">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="申请退款" :visible.sync="allocPayShow" width="600px">
      <alloc-pay v-model="allocPayForm" :detail="refundForm"></alloc-pay>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="allocPayShow = false"
          >取 消</el-button
        >
        <el-button class="addBt" @click="allocPaySubmit" :disabled="buttonFlag">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { formatDate } from "@/utils/common.js";
import {
  getReceiptOrders,
  getReceiptOrdersSearch,
  getReOrResync,
  setReOrReNotify,
  setRefund,
  exportReceiptOrders,
  setAllocRefund,
} from "@/api/trading/gathering.js";
import {
  payproductsDrop,
  paychannelsDrop,
  merchantsDrop, MerchantShopDrop
} from "@/api/common.js";
import { mapState, mapMutations } from "vuex";
import allocPay from "./components/alloc_pay";
import dropSelect from "../../../../components/dropSelect/index.vue";
import dataPanel from "../../../../components/dataPanel/index.vue";
export default {
  components: {
    allocPay,
    dropSelect,
    dataPanel,
  },
  data() {
    let that = this;
    return {
      query: {
        startTime: formatDate(+new Date(), "yyyy-MM-dd 00:00:00"),
        endTime: formatDate(+new Date(), "yyyy-MM-dd 23:59:59"),
        timeType: "CREATE",
        pageNo: 1,
        pageSize: 10,
        orderStatus: "",
        floorAmount: "",
        topAmount: "",
        productId: "", //选择的产品类型
        keyword: "",
        payChannelId: "",
        allocFund: "",
        tradeMchNo:"",
      },
      totalNum: 0, //数据总条数
      tradeList: [], //交易笔数和交易金额数据
      // tradeNum: 0,
      // tradeAmount: 0,
      productDrop: [], //产品类型数据
      channelsDrop: [],
      orderStatus: {
        NOTPAY: "未支付",
        SUCCESS: "支付成功",
        REFUND: "转入退款",
        CLOSED: "订单关闭",
        PAYERROR: "支付失败",
        USERPAYING: "用户支付中",
        REVOKED: "已撤销",
      },
      tabData: [],
      formData: {
        orderNoType: "mchOrderNo",
        orderNo: "",
      },
      orderNoType: {
        mchRefundNo: "退款订单号",
        plfRefundNo: "退款流水号",
        mchOrderNo: "商户订单号",
        plfOrderNo: "交易流水号",
        upsOrderNo: "三方订单号",
        thdOrderNo: "三方流水号",
      },
      value1: "",
      value2: "",
      crumbs: "批量订单查询",
      outPayShow: false, //导出交易展示
      outMoneyShow: false, //申请退款展示
      value3: "商户订单号", //订单查询的
      input: "",
      value: "",
      options: [],
      checked: "",
      refundForm: {},
      allRefund: false, //全额退款
      refundMoney: "", //退款金额
      outputForm: {
        startDate: "",
        endDate: "",
        mchId: "",
      },
      selectTimeType: "",
      endOption: {
        disabledDate(date) {
          return (
            date.getTime() <
              new Date(that.outputForm.startDate) - 24 * 60 * 60 * 1000 ||
            date.getTime() >
              new Date(
                +new Date(that.outputForm.startDate) + 30 * 24 * 60 * 60 * 1000
              )
          );
        },
      },
      mchDrop: [], //商户列表
      allocPayShow: false, //分账退款
      allocPayForm: {},
      orderNo: "",
      buttonFlag:false,

      MerchantShopData: [], //分账商户名称/编号可选项
      keywordData:{
        mchId:"",
        mchName:"",
      },

    };
  },
  computed: {
    ...mapState({
      dealDetail: "trading_deal",
    }),
  },
  created() {
    if (this.dealDetail) {
      this.query = this.dealDetail.query;
      this.formData = this.dealDetail.formData;
      this.keywordData = this.dealDetail.keywordData;
    }
    if(JSON.stringify(this.keywordData) != '{}' && this.keywordData != undefined && this.keywordData.mchId != ""){
      this.query.keyword = this.keywordData.mchName + ' | ' + this.keywordData.mchId;
    }
    this.$nextTick(() => {
      this.$refs.pagination.internalCurrentPage = this.query.pageNo;
    });
    this.getDrop();
    this.getLists(false);
    this.$enter(this.$route.path, this.search);
  },
  methods: {
    ...mapMutations({
      setDeal: "trading_setDeal",
    }),

    // 选择产品类型时
    selProduct(val) {
      console.log("公共组件选中的产品类型id为", val);
      this.query.productId = val;
    },
    search() {
      if (this.crumbs == "批量订单查询") {
        this.getLists();
      } else {
        this.getDetail();
      }
    },

    //搜索-交易金额
    inputnumStart(e){
      if(e == -1){
        this.query.floorAmount = "";
      }else{
        if(!(/^(\d?)+(\.\d{0,2})?$/.test(e))){  
          e = e.substring(0, e.length - 1); 
          this.$message.error("请输入小数点后两位");
        }
        this.query.floorAmount = e;
      }
    },
    inputnumEnd(e){
      if(e == -1){
        this.query.topAmount = "";
      }else{
        if(!(/^(\d?)+(\.\d{0,2})?$/.test(e))){  
          e = e.substring(0, e.length - 1); 
          this.$message.error("请输入小数点后两位");
        }
        this.query.topAmount = e;
      }
    },

    // 获取详情
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
      }
      let query = { ...this.query };
      query.floorAmount = query.floorAmount ? this.accMul(query.floorAmount, 100) : "";
      query.topAmount = query.topAmount ? this.accMul(query.topAmount, 100) : "";
      // query.keyword = query.keyword.trim();
      if (this.query.startTime > this.query.endTime) {
        return this.$message.error("结束时间不能小于开始时间");
      }
      this.tradeList = [];

      if(this.keywordData != undefined && this.keywordData.mchId != ""){
        query.keyword = this.keywordData.mchId;
      }
      getReceiptOrders(query).then((res) => {
        if (res.resultStatus) {
          if (this.crumbs == "批量订单查询") {
            this.tabData = res.resultData.orders;
            console.log("表格数据", this.tabData);
            if (this.tabData.length == 0) {
              this.$message({
                message: "暂无数据",
                duration: 1500,
              });
            }

            this.tradeList.push(
              {
                title: "交易总数（笔）",
                value: res.resultData.tradeNum,
                type: 1,
              },
              {
                title: "交易金额（元）",
                value: res.resultData.tradeAmount,
                type: 2,
              }
            );
            this.totalNum = res.resultData.totalNum;
            // this.tradeNum = res.resultData.tradeNum;
            // this.tradeAmount = res.resultData.tradeAmount;
          }
        }
      });
    },
    // 获取搜索详情
    getDetail() {
      let params = {
        orderNoType: this.formData.orderNoType,
        orderNo: this.formData.orderNo.trim(),
      };
      if (params.orderNo) {
        getReceiptOrdersSearch(params).then((res) => {
          if (res) {
            if (this.crumbs == "订单查询") {
              this.tabData = res.resultData;
              if (this.tabData.length == 0) {
                this.$message({
                  message: "暂无数据",
                  duration: 1500,
                });
              }
            }
          }
        });
      } else {
        this.$message.error(
          "请输入" + this.orderNoType[this.formData.orderNoType]
        );
        return;
      }
    },
    // 获取下拉
    getDrop() {
      payproductsDrop().then((res) => {
        if (res.resultStatus) {
           this.productDrop = res.resultData;
          // let arry = [];
          // res.resultData.forEach((e) => {
          //   arry.push({
          //     name: e.productName,
          //     id: e.productId,
          //   });
          // });
          // console.log("产品类型数据", arry);

          // this.productDrop = arry;
        }
      });
      paychannelsDrop().then((res) => {
        if (res) {
          this.channelsDrop = res.resultData;
        }
      });
      merchantsDrop().then((res) => {
        if (res) {
          this.mchDrop = res.resultData;
        }
      });
    },
    // 同步
    getSync(id, index) {
      getReOrResync(id).then((res) => {
        if (res.resultStatus) {
          this.tabData[index].orderStatus = res.resultData.orderStatus;
          this.$message.success("同步请求成功");
        }
      });
    },
    // 通知
    setNotify(id) {
      setReOrReNotify(id).then((res) => {
        if (res) {
          this.$message.success("通知请求成功");
        }
      });
    },
    // 退款展示
    setRefundShow(value) {
      if (value.allocFund) {
        this.allocPayForm = {
          orderId: value.orderId,
          refundAmount: "",
          marketingRefundAmount: "",
          MchAllocRefundList: [
            {
              allocateMchNo: "",
              allocateRefundAmount: "",
            },
          ],
          allocateNo: "",
          allocateType: 1,
        };
        //  return

        this.allocPayShow = true;
        console.log("弹窗展示,传递给组件的数据为11", this.refundForm);
      } else {
        this.orderNo = value.mchOrderNo;
        this.refundMoney = "";
        this.outMoneyShow = true;
        this.allRefund = false;
      }
      this.refundForm = value;
      console.log("弹窗展示,传递给组件的数据为", this.refundForm);
    },

    // 分账退款
    allocPaySubmit() {
      const data = JSON.parse(JSON.stringify(this.allocPayForm));
      let errorMessage = "";

      if (isNaN(parseFloat(data.refundAmount)) == true || !(/^(\d?)+(\.\d{0,2})?$/.test(data.refundAmount))) {
         return this.$message.error("交易退款金额不能为空,且只能保留小数点后两位");
      }
      // console.log("点击确定时-分账退款", this.refundForm);

      if(data.marketingRefundAmount != "" && !(/^(\d?)+(\.\d{0,2})?$/.test(data.marketingRefundAmount))) {
            this.$message.error("营销退款金额只能保留小数点后两位");
            return false;
      }

      data.refundAmount = this.accMul(data.refundAmount, 100); //交易退款的金额
      data.marketingRefundAmount = this.accMul(data.marketingRefundAmount, 100); //营销退款金额
      
      if (!data.refundAmount && !data.marketingRefundAmount) {
        errorMessage = "交易退款金额和营销退款金额不能同时为空或者0";
      }
      
      let refundSum  = data.refundAmount + data.marketingRefundAmount;
      if(refundSum > this.refundForm.refundFee){
           errorMessage = "交易退款金额 + 营销退款金额不能大于可退金额";
      }

      // console.log("data",data)

      if (this.refundForm.allocFundType == 13 && data.allocateType == 1 && data.allocateNo == "") {
        errorMessage = "分账订单号不能为空";
      }
      
      if(data.MchAllocRefundList != '' && data.allocateType == 1){
        for(let i = 0 ; i < data.MchAllocRefundList.length ; i++){
          const num = data.MchAllocRefundList[i].allocateRefundAmount;
          if(num == "" || num == 0 || !(/^(\d?)+(\.\d{0,2})?$/.test(num))) {
            this.$message.error("退款金额大于0,且只能保留小数点后两位");
            return false;
          }
        }
      }
      
      if (data.allocateType == 0) {
        console.log("点击确定时22", data);
        data.allocateNo = "";
        data.MchAllocRefundList.forEach((e) => {
          e.allocateMchNo = "";
          e.allocateRefundAmount = "";
        });
      }else{
        let refundSumAdd = 0; // 退款金额 和
        data.MchAllocRefundList.forEach((obj) => {
          if (obj.allocateRefundAmount) {
            obj.allocateRefundAmount = this.accMul(obj.allocateRefundAmount, 100);
            if(obj.allocateRefundAmount == 0){
              errorMessage = "退款金额不能为0";
              return false;
            }       
            refundSumAdd += obj.allocateRefundAmount;
          }
        });

        if(refundSumAdd != refundSum){
            errorMessage = "分账商户的退款总额 = 交易退款金额 + 营销退款金额";
        }
      }

      if (errorMessage) {
        return this.$message.error(errorMessage);
      }

      this.buttonFlag = true;
      setAllocRefund(data).then((res) => {
        setTimeout(() => {
          this.buttonFlag = false;
        }, 2000);
        if (res.resultStatus) {
          this.allocPayShow = false;
          this.$message.success("退款申请成功");
          if (this.crumbs == "批量订单查询") {
            this.getLists(false);
          } else {
            this.getDetail();
          }
        }
      });

    },
    //  处理计算失真
    accMul(arg1, arg2) {
      var m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
      try {
        m += s1.split(".")[1].length;
      } catch (e) {}
      try {
        m += s2.split(".")[1].length;
      } catch (e) {}
      return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m)
      );
    },

    // 退款
    setRefund() {
      if (!this.allRefund && !this.refundMoney) {
        this.$message.error("请选择退款金额");
        return;
      }
      if (this.refundMoney <= 0 || !(/^(\d?)+(\.\d{0,2})?$/.test(this.refundMoney))) {
        this.$message.error("请输入正确金额（大于0,且只能保留小数点后两位）");
        return;
      }
      if (this.refundForm.refundFee / 100 < this.refundMoney) {
        this.$message.error("退款金额超出可退款金额");
        return;
      }
      let data = {
        refundAmount: parseInt(this.refundMoney * 100),
      };
      data.refundAmount = this.allRefund
        ? this.refundForm.refundFee
        : parseInt(this.refundMoney * 100);

      this.buttonFlag = true;

      setRefund(this.refundForm.orderId, data).then((res) => {
        this.outMoneyShow = false;
        setTimeout(() => {
          this.buttonFlag = false;
        }, 2000);
        if (res.resultStatus) {
          this.$message.success("退款申请成功");
          if (this.crumbs == "批量订单查询") {
            this.getLists(false);
          } else {
            let params = {
              orderNoType: "mchOrderNo",
              orderNo: this.orderNo,
            };
            getReceiptOrdersSearch(params).then((res1) => {
              if (res1.resultStatus) {
                if (this.crumbs == "订单查询") {
                  this.tabData = res1.resultData;
                  if (this.tabData.length == 0) {
                    this.$message({
                      message: "暂无数据",
                      duration: 1500,
                    });
                  }
                }
              }
            });
          }
        }
      });
    },
    // 导出展示
    outputShow() {
      this.outPayShow = true;
      this.outputForm = {
        startDate: "",
        endDate: "",
        mchId: "",
      };
      this.selectTimeType = "";
    },
    // 选择时间类型
    timeChange(value) {
      switch (value) {
        case "1":
          this.outputForm.startDate = formatDate(+new Date(), "yyyy-MM-dd");
          this.outputForm.endDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case "2":
          this.outputForm.startDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.outputForm.endDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          break;
        case "7":
          this.outputForm.startDate = formatDate(
            +new Date() - 6 * 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.outputForm.endDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case "-1":
          this.outputForm.startDate = "";
          this.outputForm.endDate = "";
          break;
      }
    },
    // 导出
    output() {
      if (!this.outputForm.startDate || !this.outputForm.endDate) {
        this.$message.error("导出时间段必选");
        return;
      }
      let data = this.outputForm;
      this.buttonFlag = true;
      exportReceiptOrders(data).then((res) => {
        if (res) {
          let url = window.URL.createObjectURL(new Blob([res]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", "交易查询.csv");
          document.body.appendChild(link);
          link.click();
        }
        setTimeout(() => {
          this.buttonFlag = false;
        }, 2000);
      });
      this.outPayShow = false;
    },
    //菜单切换
    navClick(data) {
      this.crumbs = data;
      this.tabData = [];
      if (data == "批量订单查询") {
        this.getLists();
      } else {
        if (this.formData.orderNo) {
          this.getDetail();
        }
      }
    },
    // 跳转详情
    showTab(value) {
      this.$router.push({
        name: "details_xq",
        query: {
          id: value.orderId,
        },
      });
    },
    // 全额退款
    setRefundMoney(value) {
      if (value) {
        this.refundMoney = this.refundForm.refundFee / 100;
        this.refundMoney = this.refundMoney.toFixed(2);   
      }
    },

    // 获取商户下拉列表
    _searchAllMch(value) {
      this.MerchantShopData = [];
      let params = {
        keyword: value,
      };
      MerchantShopDrop(params).then((res) => {
        if (res.resultStatus) {
          this.MerchantShopData = res.resultData;
          console.log(this.MerchantShopData);
        }
      });
    },
    // 商户下拉列表输入搜索内容时
    searchMchList(val) {
      if (val) {
        let value = val;
        this._searchAllMch(value);
      } else {
        // 搜索内容为空时还原列表
        this._searchAllMch();
      }
    },
    // 商户下拉框展开/收起时触发
    unfoldMchList(e) {
      if (e) {
        //  展开时
        this._searchAllMch();
      } else {
        //  折叠时
        this.MerchantShopData = [];
      }
    },
    // 商户下拉搜索框清空搜索内容时
    clearMchList() {
      this.query.keyword = ""; //清空选中的商户id
      this.keywordData = {};
      this._searchAllMch(); //重新获取所有商户下拉列表
    },
    changeMch(e) {
      this.query.keyword = e;
      let mchId  = this.query.keyword;
      console.log( this.query.keyword );
      let result = this.MerchantShopData.filter(e=>e.mchId === mchId)
      if(result && result.length > 0){
        console.log(result[0])
        this.keywordData = result[0];
      }
    }, 

    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
      formData: this.formData,
      keywordData: this.keywordData,
    };
    this.setDeal(data);
  },
};
</script>
<style scoped>
.con_from .lineP .itemTime{
  width: 63.5%;
}
.con_from .lineP .item i {
  width: 75px;
}

.con_from .lineP .item .el-input , .con_from .lineP .item .el-select{
  width: calc(100% - 75px - 10px);
}
.con_from .lineP .itemTime .el-input{
  width: calc((100% - 130px - 75px - 44.67px - 10px) / 2);
}
.con_from .lineP .item .inputW {
  width: calc((100% - 75px - 24.67px - 10px) / 2);
}
.con_from .lineP .item .inputW ::v-deep .el-input__inner{
  padding: 0px 0px 0px 15px !important;
}
.jiaoyi_box {
  width: 49%;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}
.overAccount_ul_title i {
  color: #48b8b6;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
</style>
