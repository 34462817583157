<template>
  <div class="alloc-content">
    <div class="alloc-content-title">分账订单详情</div>
    <div class="tab1" style="min-height:auto;">
      <table class="tab">
        <tr class="tab_title">
          <td>
            <p>序号</p>
          </td>
          <td>
            <p>分账订单号</p>
            <p>分账流水号</p>
          </td>
          <td>
            <p>分账商户名称</p>
            <p>分账商户编号</p>
          </td>
          <td>
            <p>分账金额</p>
          </td>
          <td>
            <p>已退金额</p>
          </td>
        </tr>

        <template v-if="detail.allocInfoList">
          <tr v-for="(v, i) in detail.allocInfoList" :key="i">
            <td>
              <p>{{ i + 1 }}</p>
            </td>
            <td>
              <p>{{ v.mchAllocOrderNo }}</p>
              <p>{{ v.allocTrxNo }}</p>
            </td>
            <td>
              <p>
                {{ v.merchantType == "MERCHANT" ? v.mchName : v.allocMchName }}
              </p>
              <p>{{ v.merchantType == "MERCHANT" ? v.mchId : v.allocMchId }}</p>
            </td>
            <td>
              <p>{{ (v.allocAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ (v.refundAllocAmount / 100) | formatMoney }}</p>
            </td>
          </tr>
        </template>
      </table>
    </div>
    <ul class="overAccount_ul">
      <li>
        <div class="overAccount_ul_title">交易金额(元)</div>
        <div class="overAccount_ul_content">
          {{ (detail.orderAmount / 100) | formatMoney }}
        </div>
      </li>
      <li>
        <div class="overAccount_ul_title">营销金额(元)</div>
        <div class="overAccount_ul_content">
          {{ (detail.marketingFee / 100) | formatMoney }}
        </div>
      </li>
      <li>
        <div class="overAccount_ul_title">可退金额(元)</div>
        <div class="overAccount_ul_content">
          {{ (detail.refundFee / 100) | formatMoney }}
        </div>
      </li>
    </ul>
    <ul class="overAccount_ul">
      <li>
        <div class="overAccount_ul_title"><i>*</i>交易退款金额(元)</div>
        <el-input
          type="number"
          onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
          v-model="value.refundAmount"
          placeholder="输入退款金额"
          step="0.01"
          min="0.00"
        ></el-input>
        <div class="overAccount_ul_title" v-if="detail.allocFundType == 13">
          <i>*</i>退款类型
        </div>
        <el-select
          v-model="value.allocateType"
          placeholder="全部"
          style="width: 200px; height: 36px"
          v-if="detail.allocFundType == 13"
        >
          <el-option
            v-for="(v, i) in refundTypeList"
            :key="i"
            :label="v.lable"
            :value="v.value"
          ></el-option>
        </el-select>
        <template v-for="(v, i) in value.MchAllocRefundList">
          <div
            class="overAccount_ul_title"
            :key="i"
            v-if="value.allocateType == 1 || detail.allocFundType != 13"
          >
          <i>*</i>分账商户编号
          </div>
          <el-input
            type="text"
            v-if="value.allocateType == 1 || detail.allocFundType != 13"
            v-model="value.MchAllocRefundList[i].allocateMchNo"
            placeholder="输入分账商户编号"
            :key="i"
          ></el-input>
        </template>
      </li>
      <li>
        <div class="overAccount_ul_title">营销退款金额(元)</div>
        <!-- oninput="value=value.replace(/[^\d.]/g,'')" -->
        <el-input
          type="number"
          onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
          v-model="value.marketingRefundAmount"
          placeholder="输入营销退款金额"
          step="0.01"
          min="0.00"
        ></el-input>
        <!-- <div
          class="overAccount_ul_title"
          v-if="detail.allocFundType == 13"
        ></div> -->
        <div
          class="overAccount_ul_title"
          v-if="detail.allocFundType == 13 && value.allocateType == 1"
        >
          <i>*</i>分账订单号
        </div>
        <el-input
          type="text"
          v-model="value.allocateNo"
          placeholder="输入分账订单号"
          v-if="detail.allocFundType == 13 && value.allocateType == 1"
        ></el-input>
        <!-- <div
          class="overAccount_ul_content"
          v-if="detail.allocFundType == 13"
        ></div> -->
        <template v-for="(v, i) in value.MchAllocRefundList">
          <div
            class="overAccount_ul_title"
            :key="'info2-' + i"
            v-if="value.allocateType == 1 || detail.allocFundType != 13"
          >
            <i>*</i>退款金额
            <span class="handle-bt" v-if="i == 0" @click="addMchAlloc">+</span>
            <span class="handle-bt" v-if="i != 0" @click="removeMchAlloc(i)"
              >-</span
            >
          </div>
          <!-- oninput="value=value.replace(/[^\d.]/g,'')" -->
          <el-input
            type="number"
            v-if="value.allocateType == 1 || detail.allocFundType != 13"
            onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
            v-model="value.MchAllocRefundList[i].allocateRefundAmount"
            placeholder="输入退款金额"
            step="0.01"
            min="0.01"
            :key="'info3-' + i"
          ></el-input>
        </template>
      </li>
    </ul>
    <div class="overAccount_ul_title">
      分账商户的退款总额 = 交易退款金额 + 营销退款金额
    </div>
    <!-- <div class="overAccount_ul_title">注：无法单独退款营销金额</div> -->
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          refundAmount:'',
        };
      },
    },
    detail: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      refundTypeList: [
        {
          lable: "未分账退款",
          value: 0,

        },
        {
          lable: "已分账退款",
          value: 1,

        },
      ],
    };
  },
  methods: {
    addMchAlloc() {
      let value = this.value;
      value.MchAllocRefundList.push({
        allocateMchNo: "",
        allocateRefundAmount: "",
      });
      this.$emit("input", value);
    },
    removeMchAlloc(index) {
      let value = this.value;
      value.MchAllocRefundList.splice(index, 1);
      this.$emit("input", value);
    },
  },
};
</script>

<style scoped>
.alloc-content {
  margin: 0 20px;
}
.tab tr td p {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}

.tab_title {
  background: rgba(244, 245, 246, 1);
  border-top: 1px solid rgba(220, 224, 230, 1);
}

.tab1 .tab tr {
  padding-left: 20px;
  height: 60px;
  border-bottom: 1px solid rgba(220, 224, 230, 1);
}

.tab {
  border-collapse: collapse;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid rgba(220, 224, 230, 1);
}

.tab1 {
  width: 100%;
  margin-bottom: 20px;
}
.overAccount_ul {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
.overAccount_ul li {
  width: 200px;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}
.overAccount_ul_title i {
  color: #48b8b6;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul_content {
  line-height: 40px;
  height: 40px;
  margin-bottom: 7px;
}
.handle-bt {
  float: right;
  padding: 0 10px;
  cursor: pointer;
  color: #48b8b6;
  font-size: 22px;
}
.alloc-content-title {
  font-size: 16px;
  line-height: 40px;
}
</style>